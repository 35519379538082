"use client";
import { SearchTerm } from "@/api/searchTerms/types";
// import { useSearchParams, useRouter } from "next/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { twJoin } from "tailwind-merge";

export interface Props {
  searchTerms: SearchTerm[];
  onChange?: (searchTerm: string) => void;
  className?: string;
}

const SearchTermsList = ({ searchTerms, onChange, className }: Props) => {
  const handleClick = (e: any) => {
    onChange?.(e.target.innerHTML);
  };

  const SearchTermItem = ({ searchTerm }: { searchTerm: SearchTerm }) => {
    return (
      <div
        key={searchTerm.id}
        className="p-4 font-light hover:bg-gray-100 border-[1px] border-[#cecece] rounded-2xl cursor-pointer"
        onClick={handleClick}
      >
        {searchTerm.text}
      </div>
    );
  };

  return (
    <>
      <div
        className={twJoin(
          "hidden md:grid grid-cols-2 gap-4 max-w-[600px] w-full lg:my-8 my-4",
          className,
        )}
      >
        {searchTerms.map((term, index) => (
          <SearchTermItem searchTerm={term} key={index} />
        ))}
      </div>
      <div className={className}>
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={20}
          modules={[Pagination]}
          rewind={true}
          className="md:!hidden w-full max-w-[300px] !overflow-visible !py-4"
        >
          {searchTerms.map((term, index) => (
            <SwiperSlide key={index}>
              <SearchTermItem searchTerm={term} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default SearchTermsList;
