"use client";
import React, { useState } from "react";
import SearchIcon from "@/assets/icons/search.svg";
import CameraColoredIcon from "@/assets/icons/camera-colored.svg";
import ArrowRightIcon from "@/assets/icons/arrow-right.svg";
import { twJoin, twMerge } from "tailwind-merge";
import { ArtworkUpload } from "../../Create/Nft/ArtworkUploadSection/ArtworkUpload";
import { SUPPORTED_NFT_TYPES } from "@/shared/media";
import { Formik, Form } from "formik";
import { FormInput } from "@/app/(components)/Basic/FormInput";
import FormDropdown from "@/app/(components)/Basic/FormDropdown/FormDropdown";
import {
  marketplaceOptions,
  blockchainOptions,
  // priceOptions,
} from "@/shared/constants";
import { OptionPicker } from "@/app/(components)/Basic/DropdownInputBase/types";
import OfferIcon from "@/assets/icons/offer.svg";
import ChainIcon from "@/assets/icons/chain.svg";
// import PriceIcon from "@/assets/icons/tag-price.svg";
import { SearchFilter } from "../types";
import { Tooltip } from "@/app/(components)/Basic/Tooltip";
import { SearchTermsList } from "../SearchTermsList";
import { SearchTerm } from "@/api/searchTerms/types";

export interface Props extends SearchFilter {
  showFilters?: boolean;
  className?: string;
  searchTerms?: SearchTerm[];
  onChange?: (searchFilters: SearchFilter) => void;
  hideSearchTerms?: boolean;
}

const AISearchInput = ({
  showFilters,
  className,
  searchTerms,
  onChange,
  hideSearchTerms,
}: Props) => {
  const imageSimilarityPercentages = ["20%", "50%", "70%", "90%", "100%"];

  const [isImageUploadOpen, setIsImageUploadOpen] = useState(false);

  const initialData = {
    searchTerm: "",
    similarity: "",
    marketplaces: [],
    blockchains: [],
  };

  const onSubmit = async (filter: SearchFilter) => {
    onChange?.(filter);
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialData}>
      {({ setFieldValue, values, touched, submitForm }) => {
        return (
          <Form className="flex flex-col items-center relative w-full">
            <SearchTermsList
              className={twJoin(
                "transition-all duration-500 ease-in-out",
                hideSearchTerms && "!max-h-0 !m-0 !p-0 !overflow-hidden",
              )}
              searchTerms={searchTerms || []}
              onChange={async (searchTerm) => {
                setFieldValue("searchTerm", searchTerm);
                await submitForm();
              }}
            />
            <div
              className={twMerge(
                "transition-[max-height] duration-700 ease-in-out box-border border-2 border-primary border-solid rounded-[32px] p-3 flex items-center light:bg-primary night:bg-primary max-w-[600px] w-full",
                "z-20",
                isImageUploadOpen ? "max-h-[1000px]" : "max-h-16",
                className,
              )}
            >
              <div>
                <div className="flex items-center h-10">
                  <SearchIcon className="w-5 h-5 fill-endemicGray-700 ml-2 endemic:fill-secondary" />
                  <FormInput
                    name="searchTerm"
                    type="text"
                    placeholder="Search artwork..."
                    className="border-none outline-none text-base bg-transparent text-primary ml-4 p-0 mt-7 mb-0 w-full"
                    alignCenter={false}
                  />
                  <CameraColoredIcon
                    className="w-6 h-6 ml-auto cursor-pointer"
                    onClick={() => setIsImageUploadOpen((prev) => !prev)}
                  />
                  <button
                    className={twMerge(
                      "min-w-10 min-h-10 rounded-full bg-[#f0f0f0] flex items-center justify-center ml-4",
                      (!!values.image || !!values.searchTerm) &&
                        "cursor-pointer  hover:bg-red hover:fill-white",
                    )}
                    type="submit"
                    disabled={!values.image && !values.searchTerm}
                  >
                    <ArrowRightIcon className="w-5 h-5" />
                  </button>
                </div>

                <div
                  className={twMerge(
                    "transition-[max-height] duration-700 ease-in-out overflow-hidden",
                    isImageUploadOpen ? "max-h-[900px]" : "max-h-0",
                  )}
                >
                  <ArtworkUpload
                    name="image"
                    text="Adding image to your search helps match the style you’re looking for"
                    acceptTypes={SUPPORTED_NFT_TYPES}
                    maxFileSize={5}
                    onClose={() => {
                      setFieldValue("image", null);
                      setFieldValue("similarity", "");
                      setIsImageUploadOpen(false);
                    }}
                    onRemove={() => {
                      setFieldValue("similarity", "");
                    }}
                  />
                  <div className="flex flex-col items-center mt-4 mb-3">
                    <span className="text-endemicGray-700 text-sm mb-2 endemic:text-secondary">
                      Similarity percentage
                    </span>
                    <div className="flex justify-center flex-wrap gap-4 w-full">
                      {imageSimilarityPercentages.map((p, index) => (
                        <Tooltip
                          text="You must upload image if you want select similarity"
                          position={["center", "top"]}
                          tooltipClassName={twMerge(
                            "w-[100px] text-center",
                            values.image && "hidden",
                          )}
                          key={index}
                        >
                          <input
                            name="similarity"
                            type="button"
                            value={p}
                            className={twMerge(
                              "rounded-[16px] h-8 text-[16px] leading-5 flex items-center justify-center w-[100px] tracking-wide cursor-pointer",
                              p == values.similarity
                                ? "bg-red text-white font-semibold"
                                : "bg-[#f0f0f0] text-invert light:text-primary font-light",
                            )}
                            onClick={() => {
                              p == values.similarity
                                ? setFieldValue("similarity", "")
                                : setFieldValue("similarity", p);
                            }}
                            disabled={!values.image}
                          />
                        </Tooltip>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!values.searchTerm &&
              !values.image &&
              (touched.searchTerm || touched.image) && (
                <span className="relative text-red">
                  Search term or image are required
                </span>
              )}
            {showFilters ? (
              <div className="flex w-full mt-10 flex-start gap-8 flex-col lg:flex-row items-center">
                <FormDropdown
                  options={marketplaceOptions}
                  name="marketplaces"
                  className="w-[320px]"
                  childrenClassName="w-[320px]"
                  allThemesWithWhiteBackground
                  borderClassName="endemic:border-invert"
                  isMultiple
                  dropdownLabel="Marketplace:"
                  optionPicker={OptionPicker.CHECKBOX}
                  startIcon={<OfferIcon />}
                  wrapperClassName="mb-0 max-w-[320px]"
                />
                <FormDropdown
                  options={blockchainOptions}
                  name="blockchains"
                  className="w-[320px]"
                  childrenClassName="w-[320px]"
                  allThemesWithWhiteBackground
                  borderClassName="endemic:border-invert"
                  isMultiple
                  dropdownLabel="Chain:"
                  optionPicker={OptionPicker.CHECKBOX}
                  startIcon={<ChainIcon className="fill-primary" />}
                  wrapperClassName="mb-0 max-w-[320px]"
                />
                {/* <FormDropdown
                options={priceOptions}
                name="price"
                className="w-[320px]"
                childrenClassName="w-[320px]"
                allThemesWithWhiteBackground
                borderClassName="endemic:border-invert"
                startIcon={<PriceIcon className="w-8 h-8" />}
                dropdownLabel={<span className="font-light">Price:</span>}
                wrapperClassName="mb-0 max-w-[320px]"
              /> */}
              </div>
            ) : null}
          </Form>
        );
      }}
    </Formik>
  );
};

export default AISearchInput;
